import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a81f39fc = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _8821fac8 = () => interopDefault(import('../pages/agreement.vue' /* webpackChunkName: "pages/agreement" */))
const _cb32180c = () => interopDefault(import('../pages/appCompany.vue' /* webpackChunkName: "pages/appCompany" */))
const _3e245e28 = () => interopDefault(import('../pages/certificate/index.vue' /* webpackChunkName: "pages/certificate/index" */))
const _72a1773e = () => interopDefault(import('../pages/community/index.vue' /* webpackChunkName: "pages/community/index" */))
const _3378f20f = () => interopDefault(import('../pages/company.vue' /* webpackChunkName: "pages/company" */))
const _0cb4ef90 = () => interopDefault(import('../pages/development/index.vue' /* webpackChunkName: "pages/development/index" */))
const _19e67b38 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _215e354b = () => interopDefault(import('../pages/forget.vue' /* webpackChunkName: "pages/forget" */))
const _37504500 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _77188cda = () => interopDefault(import('../pages/knowledge/index.vue' /* webpackChunkName: "pages/knowledge/index" */))
const _64ed7215 = () => interopDefault(import('../pages/lecturer/index.vue' /* webpackChunkName: "pages/lecturer/index" */))
const _8697350a = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _990375a6 = () => interopDefault(import('../pages/permission.vue' /* webpackChunkName: "pages/permission" */))
const _f98304fe = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _7905c178 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _22359282 = () => interopDefault(import('../pages/tpl.vue' /* webpackChunkName: "pages/tpl" */))
const _a6251cb8 = () => interopDefault(import('../pages/watch/index.vue' /* webpackChunkName: "pages/watch/index" */))
const _98ff5046 = () => interopDefault(import('../pages/development/estimate/index.vue' /* webpackChunkName: "pages/development/estimate/index" */))
const _1098f8f0 = () => interopDefault(import('../pages/development/qualification/index.vue' /* webpackChunkName: "pages/development/qualification/index" */))
const _a402336c = () => interopDefault(import('../pages/development/study/index.vue' /* webpackChunkName: "pages/development/study/index" */))
const _f90eda24 = () => interopDefault(import('../pages/home/rank.vue' /* webpackChunkName: "pages/home/rank" */))
const _9e7a7cae = () => interopDefault(import('../pages/integral/mall.vue' /* webpackChunkName: "pages/integral/mall" */))
const _40bf1cb3 = () => interopDefault(import('../pages/investigate/view.vue' /* webpackChunkName: "pages/investigate/view" */))
const _6fa7ab72 = () => interopDefault(import('../pages/watch/catePanel.vue' /* webpackChunkName: "pages/watch/catePanel" */))
const _5b4fa539 = () => interopDefault(import('../pages/watch/course.vue' /* webpackChunkName: "pages/watch/course" */))
const _906a664e = () => interopDefault(import('../pages/watch/practice.vue' /* webpackChunkName: "pages/watch/practice" */))
const _544f01ba = () => interopDefault(import('../pages/watch/task.vue' /* webpackChunkName: "pages/watch/task" */))
const _01446e1e = () => interopDefault(import('../pages/account/exam/detail.vue' /* webpackChunkName: "pages/account/exam/detail" */))
const _59d1e9c5 = () => interopDefault(import('../pages/account/exam/examQuestion.vue' /* webpackChunkName: "pages/account/exam/examQuestion" */))
const _547875f4 = () => interopDefault(import('../pages/account/exam/examSuccess.vue' /* webpackChunkName: "pages/account/exam/examSuccess" */))
const _68f01cf1 = () => interopDefault(import('../pages/account/exam/record.vue' /* webpackChunkName: "pages/account/exam/record" */))
const _4d178ba7 = () => interopDefault(import('../pages/account/plan/detail.vue' /* webpackChunkName: "pages/account/plan/detail" */))
const _799d193e = () => interopDefault(import('../pages/development/estimate/batchEvaluation.vue' /* webpackChunkName: "pages/development/estimate/batchEvaluation" */))
const _6710137e = () => interopDefault(import('../pages/development/estimate/evaluation.vue' /* webpackChunkName: "pages/development/estimate/evaluation" */))
const _05c0c6b9 = () => interopDefault(import('../pages/development/estimate/report.vue' /* webpackChunkName: "pages/development/estimate/report" */))
const _2125ec26 = () => interopDefault(import('../pages/account/plan/task/add.vue' /* webpackChunkName: "pages/account/plan/task/add" */))
const _b77b3296 = () => interopDefault(import('../pages/account/plan/task/detail.vue' /* webpackChunkName: "pages/account/plan/task/detail" */))
const _28a798c8 = () => interopDefault(import('../pages/knowledge/offcourse/components/AssessAttachment.vue' /* webpackChunkName: "pages/knowledge/offcourse/components/AssessAttachment" */))
const _c7b2146c = () => interopDefault(import('../pages/account/exam/_id.vue' /* webpackChunkName: "pages/account/exam/_id" */))
const _98b6a5a0 = () => interopDefault(import('../pages/account/task/_id.vue' /* webpackChunkName: "pages/account/task/_id" */))
const _ee4e0e88 = () => interopDefault(import('../pages/integral/goods/_id.vue' /* webpackChunkName: "pages/integral/goods/_id" */))
const _15f73ad9 = () => interopDefault(import('../pages/investigate/analyze/_id.vue' /* webpackChunkName: "pages/investigate/analyze/_id" */))
const _32fd03d5 = () => interopDefault(import('../pages/knowledge/course/_id.vue' /* webpackChunkName: "pages/knowledge/course/_id" */))
const _1f243294 = () => interopDefault(import('../pages/knowledge/offcourse/_id.vue' /* webpackChunkName: "pages/knowledge/offcourse/_id" */))
const _116e2a76 = () => interopDefault(import('../pages/knowledge/project/_id.vue' /* webpackChunkName: "pages/knowledge/project/_id" */))
const _521bc65d = () => interopDefault(import('../pages/lecturer/view/_id.vue' /* webpackChunkName: "pages/lecturer/view/_id" */))
const _e4b29136 = () => interopDefault(import('../pages/live/watch/_watch.vue' /* webpackChunkName: "pages/live/watch/_watch" */))
const _4685ff22 = () => interopDefault(import('../pages/redirect/_path.vue' /* webpackChunkName: "pages/redirect/_path" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _a81f39fc,
    name: "account___zh"
  }, {
    path: "/agreement",
    component: _8821fac8,
    name: "agreement___zh"
  }, {
    path: "/appCompany",
    component: _cb32180c,
    name: "appCompany___zh"
  }, {
    path: "/certificate",
    component: _3e245e28,
    name: "certificate___zh"
  }, {
    path: "/community",
    component: _72a1773e,
    name: "community___zh"
  }, {
    path: "/company",
    component: _3378f20f,
    name: "company___zh"
  }, {
    path: "/development",
    component: _0cb4ef90,
    name: "development___zh"
  }, {
    path: "/en",
    component: _19e67b38,
    name: "index___en"
  }, {
    path: "/forget",
    component: _215e354b,
    name: "forget___zh"
  }, {
    path: "/home",
    component: _37504500,
    name: "home___zh"
  }, {
    path: "/knowledge",
    component: _77188cda,
    name: "knowledge___zh"
  }, {
    path: "/lecturer",
    component: _64ed7215,
    name: "lecturer___zh"
  }, {
    path: "/login",
    component: _8697350a,
    name: "login___zh"
  }, {
    path: "/permission",
    component: _990375a6,
    name: "permission___zh"
  }, {
    path: "/register",
    component: _f98304fe,
    children: [{
      path: "",
      component: _7905c178,
      name: "register___zh"
    }]
  }, {
    path: "/tpl",
    component: _22359282,
    name: "tpl___zh"
  }, {
    path: "/watch",
    component: _a6251cb8,
    name: "watch___zh"
  }, {
    path: "/development/estimate",
    component: _98ff5046,
    name: "development-estimate___zh"
  }, {
    path: "/development/qualification",
    component: _1098f8f0,
    name: "development-qualification___zh"
  }, {
    path: "/development/study",
    component: _a402336c,
    name: "development-study___zh"
  }, {
    path: "/en/account",
    component: _a81f39fc,
    name: "account___en"
  }, {
    path: "/en/agreement",
    component: _8821fac8,
    name: "agreement___en"
  }, {
    path: "/en/appCompany",
    component: _cb32180c,
    name: "appCompany___en"
  }, {
    path: "/en/certificate",
    component: _3e245e28,
    name: "certificate___en"
  }, {
    path: "/en/community",
    component: _72a1773e,
    name: "community___en"
  }, {
    path: "/en/company",
    component: _3378f20f,
    name: "company___en"
  }, {
    path: "/en/development",
    component: _0cb4ef90,
    name: "development___en"
  }, {
    path: "/en/forget",
    component: _215e354b,
    name: "forget___en"
  }, {
    path: "/en/home",
    component: _37504500,
    name: "home___en"
  }, {
    path: "/en/knowledge",
    component: _77188cda,
    name: "knowledge___en"
  }, {
    path: "/en/lecturer",
    component: _64ed7215,
    name: "lecturer___en"
  }, {
    path: "/en/login",
    component: _8697350a,
    name: "login___en"
  }, {
    path: "/en/permission",
    component: _990375a6,
    name: "permission___en"
  }, {
    path: "/en/register",
    component: _f98304fe,
    children: [{
      path: "",
      component: _7905c178,
      name: "register___en"
    }]
  }, {
    path: "/en/tpl",
    component: _22359282,
    name: "tpl___en"
  }, {
    path: "/en/watch",
    component: _a6251cb8,
    name: "watch___en"
  }, {
    path: "/home/rank",
    component: _f90eda24,
    name: "home-rank___zh"
  }, {
    path: "/integral/mall",
    component: _9e7a7cae,
    name: "integral-mall___zh"
  }, {
    path: "/investigate/view",
    component: _40bf1cb3,
    name: "investigate-view___zh"
  }, {
    path: "/watch/catePanel",
    component: _6fa7ab72,
    name: "watch-catePanel___zh"
  }, {
    path: "/watch/course",
    component: _5b4fa539,
    name: "watch-course___zh"
  }, {
    path: "/watch/practice",
    component: _906a664e,
    name: "watch-practice___zh"
  }, {
    path: "/watch/task",
    component: _544f01ba,
    name: "watch-task___zh"
  }, {
    path: "/account/exam/detail",
    component: _01446e1e,
    name: "account-exam-detail___zh"
  }, {
    path: "/account/exam/examQuestion",
    component: _59d1e9c5,
    name: "account-exam-examQuestion___zh"
  }, {
    path: "/account/exam/examSuccess",
    component: _547875f4,
    name: "account-exam-examSuccess___zh"
  }, {
    path: "/account/exam/record",
    component: _68f01cf1,
    name: "account-exam-record___zh"
  }, {
    path: "/account/plan/detail",
    component: _4d178ba7,
    name: "account-plan-detail___zh"
  }, {
    path: "/development/estimate/batchEvaluation",
    component: _799d193e,
    name: "development-estimate-batchEvaluation___zh"
  }, {
    path: "/development/estimate/evaluation",
    component: _6710137e,
    name: "development-estimate-evaluation___zh"
  }, {
    path: "/development/estimate/report",
    component: _05c0c6b9,
    name: "development-estimate-report___zh"
  }, {
    path: "/en/development/estimate",
    component: _98ff5046,
    name: "development-estimate___en"
  }, {
    path: "/en/development/qualification",
    component: _1098f8f0,
    name: "development-qualification___en"
  }, {
    path: "/en/development/study",
    component: _a402336c,
    name: "development-study___en"
  }, {
    path: "/en/home/rank",
    component: _f90eda24,
    name: "home-rank___en"
  }, {
    path: "/en/integral/mall",
    component: _9e7a7cae,
    name: "integral-mall___en"
  }, {
    path: "/en/investigate/view",
    component: _40bf1cb3,
    name: "investigate-view___en"
  }, {
    path: "/en/watch/catePanel",
    component: _6fa7ab72,
    name: "watch-catePanel___en"
  }, {
    path: "/en/watch/course",
    component: _5b4fa539,
    name: "watch-course___en"
  }, {
    path: "/en/watch/practice",
    component: _906a664e,
    name: "watch-practice___en"
  }, {
    path: "/en/watch/task",
    component: _544f01ba,
    name: "watch-task___en"
  }, {
    path: "/account/plan/task/add",
    component: _2125ec26,
    name: "account-plan-task-add___zh"
  }, {
    path: "/account/plan/task/detail",
    component: _b77b3296,
    name: "account-plan-task-detail___zh"
  }, {
    path: "/en/account/exam/detail",
    component: _01446e1e,
    name: "account-exam-detail___en"
  }, {
    path: "/en/account/exam/examQuestion",
    component: _59d1e9c5,
    name: "account-exam-examQuestion___en"
  }, {
    path: "/en/account/exam/examSuccess",
    component: _547875f4,
    name: "account-exam-examSuccess___en"
  }, {
    path: "/en/account/exam/record",
    component: _68f01cf1,
    name: "account-exam-record___en"
  }, {
    path: "/en/account/plan/detail",
    component: _4d178ba7,
    name: "account-plan-detail___en"
  }, {
    path: "/en/development/estimate/batchEvaluation",
    component: _799d193e,
    name: "development-estimate-batchEvaluation___en"
  }, {
    path: "/en/development/estimate/evaluation",
    component: _6710137e,
    name: "development-estimate-evaluation___en"
  }, {
    path: "/en/development/estimate/report",
    component: _05c0c6b9,
    name: "development-estimate-report___en"
  }, {
    path: "/knowledge/offcourse/components/AssessAttachment",
    component: _28a798c8,
    name: "knowledge-offcourse-components-AssessAttachment___zh"
  }, {
    path: "/en/account/plan/task/add",
    component: _2125ec26,
    name: "account-plan-task-add___en"
  }, {
    path: "/en/account/plan/task/detail",
    component: _b77b3296,
    name: "account-plan-task-detail___en"
  }, {
    path: "/en/knowledge/offcourse/components/AssessAttachment",
    component: _28a798c8,
    name: "knowledge-offcourse-components-AssessAttachment___en"
  }, {
    path: "/",
    component: _19e67b38,
    name: "index___zh"
  }, {
    path: "/en/account/exam/:id?",
    component: _c7b2146c,
    name: "account-exam-id___en"
  }, {
    path: "/en/account/task/:id?",
    component: _98b6a5a0,
    name: "account-task-id___en"
  }, {
    path: "/en/integral/goods/:id?",
    component: _ee4e0e88,
    name: "integral-goods-id___en"
  }, {
    path: "/en/investigate/analyze/:id?",
    component: _15f73ad9,
    name: "investigate-analyze-id___en"
  }, {
    path: "/en/knowledge/course/:id?",
    component: _32fd03d5,
    name: "knowledge-course-id___en"
  }, {
    path: "/en/knowledge/offcourse/:id?",
    component: _1f243294,
    name: "knowledge-offcourse-id___en"
  }, {
    path: "/en/knowledge/project/:id?",
    component: _116e2a76,
    name: "knowledge-project-id___en"
  }, {
    path: "/en/lecturer/view/:id?",
    component: _521bc65d,
    name: "lecturer-view-id___en"
  }, {
    path: "/en/live/watch/:watch?",
    component: _e4b29136,
    name: "live-watch-watch___en"
  }, {
    path: "/account/exam/:id?",
    component: _c7b2146c,
    name: "account-exam-id___zh"
  }, {
    path: "/account/task/:id?",
    component: _98b6a5a0,
    name: "account-task-id___zh"
  }, {
    path: "/en/redirect/:path?",
    component: _4685ff22,
    name: "redirect-path___en"
  }, {
    path: "/integral/goods/:id?",
    component: _ee4e0e88,
    name: "integral-goods-id___zh"
  }, {
    path: "/investigate/analyze/:id?",
    component: _15f73ad9,
    name: "investigate-analyze-id___zh"
  }, {
    path: "/knowledge/course/:id?",
    component: _32fd03d5,
    name: "knowledge-course-id___zh"
  }, {
    path: "/knowledge/offcourse/:id?",
    component: _1f243294,
    name: "knowledge-offcourse-id___zh"
  }, {
    path: "/knowledge/project/:id?",
    component: _116e2a76,
    name: "knowledge-project-id___zh"
  }, {
    path: "/lecturer/view/:id?",
    component: _521bc65d,
    name: "lecturer-view-id___zh"
  }, {
    path: "/live/watch/:watch?",
    component: _e4b29136,
    name: "live-watch-watch___zh"
  }, {
    path: "/redirect/:path?",
    component: _4685ff22,
    name: "redirect-path___zh"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
